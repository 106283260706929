import React, { useState, useEffect } from 'react';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButton } from 'react-paypal-button-v2';
import Message from '../components/Message';
import LoadSpinner from '../components/LoadSpinner';
import { getOrderDetails, payOrder, deliverOrder, calculateCheckout, youkeOrder } from '../actions/orderActions';
import { CART_CLEAR_ITEMS } from '../constants/cartConstants';

function OrderTouristScreen({ match, history }) {
    const dispatch = useDispatch();
    const [isPay, setIsPay] = useState(false);
    const [sdkReady, setSdkReady] = useState(false);

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const { checkoutDetail, loading } = useSelector(state => state.order);
    const messageInfo = useSelector(state => state.message);
    const cart = useSelector(state => state.cart)
    console.log("Cart state:", cart, "Items List:", cart?.itemsList); // 调试信息
    const shippingAddress = useSelector(state => state.cart.shippingAddress);
    const orderInfo = useSelector(state => state.order)

    // Extract checkout details
    const itemsPrice = checkoutDetail?.items_price || 0;
    const yunfei = checkoutDetail?.shipping_fee || 0;
    const Shui = checkoutDetail?.tax || 0;
    const totalPrice = checkoutDetail?.final_price || 0;

    const addPayPalScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.paypal.com/sdk/js?client-id=AffNWpCeJZ9VLsUAWLrEatRj0RcQFiU-urRdNGmdchQl__OdrLclosh0UUkbGq57b2_Ao-b9ay3w30qn&currency=GBP';
        //        script.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=GBP'
        script.async = true;
        script.onload = () => setSdkReady(true);
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (!isPay) {
            if (!window.paypal) {
                addPayPalScript();
            } else {
                setSdkReady(true);
            }

            // Fetch checkout details
            dispatch(calculateCheckout({ orderId: match.params.id }));
        }
    }, [dispatch, isPay, match.params.id]);

    const youkePay = () => {
        const param = {
            get_way: match.url.includes('zitiquhuo') ? '自提取货' : '送货上门',
            TotalPrices: itemsPrice,
            Yunfei: yunfei,
            Amount: totalPrice,
            Shui: Shui,
            Items: cart.cartItems,
            ItemsJson: cart.itemsList,
            receiver: '',
            phone: '',
            email: shippingAddress.email,
            company: '',
            nation: '',
            province: '',
            city: '',
            postcode: '',
            addinfo: ''
        };

        // 根据 match.url 判断是否添加其它字段
        if (match.url !== '/zitiquhuo/tourist') {
            param.receiver = shippingAddress.uname;
            param.phone = shippingAddress.phone;
            param.company = shippingAddress.gs;
            param.nation = shippingAddress.country;
            param.province = shippingAddress.county;
            param.city = shippingAddress.city;
            param.postcode = shippingAddress.postcode;
            param.addinfo = shippingAddress.address;
        }

        dispatch(youkeOrder(param, () => {
            setIsPay(true);
            dispatch({ type: CART_CLEAR_ITEMS });
        }));
    };


    // example paypal payment response: https://developer.paypal.com/sdk/js/reference/#link-response
    const successPaymentHandler = (paypalPaymentResponse) => {
        // parse amount got and send to gtag
        const orderId = paypalPaymentResponse["id"];
        const unitsPurchasedList = paypalPaymentResponse["purchase_units"];
        let currencyPaidMap = {}; // key: currency, value: amount

        for(let i = 0 ; i<unitsPurchasedList.length ;i++) {
            const unitsPurchased = unitsPurchasedList[i];
            const curPurchaseCurrencyCode = unitsPurchased["amount"]["currency_code"];
            if (curPurchaseCurrencyCode in currencyPaidMap) {
                currencyPaidMap[curPurchaseCurrencyCode] += parseFloat(unitsPurchased["amount"]["value"]);
            } else {
                currencyPaidMap[curPurchaseCurrencyCode] = parseFloat(unitsPurchased["amount"]["value"]);
            }
        }
        // call gtag on for purchas_success conversion
        for (const [currencyType, currencyValue] of Object.entries(currencyPaidMap)) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16659322663/C6qJCLnxkowaEKeu5Ic-',
                'transaction_id': orderId,
                'currency': currencyType,
                'value': currencyValue
            });
        }

        // render pay complete screen
        youkePay();
    };

    return (
        <div className="OrderDetailScreen maxWidth">
            {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

            {loading ? (
                <div className="fullscreen"><LoadSpinner /></div>
            ) : (
                <div>
                    <Row>
                        <Col md={8}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h2>Shipping</h2>
                                    <p><strong>Email: </strong><a href={`mailto:${shippingAddress.email}`}>{shippingAddress.email}</a></p>
                                    <p><strong>Address: </strong>{shippingAddress.address || 'Not Provided'}</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>Payment Method</h2>
                                    <p><strong>Method: </strong>PayPal</p>
                                    {isPay ? (
                                        <Message variant="success">Paid Successfully</Message>
                                    ) : (
                                        <Message variant="danger">Not Paid</Message>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h2>Order Items</h2>
                                        {cart && cart.itemsList && cart.itemsList.length > 0 ? (
                                            <ListGroup variant="flush">
                                                {cart.itemsList.map((item) => {
                                                    console.log("Item in map:", item);
                                                    return (
                                                        <ListGroup.Item key={item.product}>
                                                            <Row>
                                                                <Col md={1}>
                                                                    <img
                                                                        style={{width: '100%', height: '100%'}}
                                                                        src={(item.image || '/default-image.jpg').split('?')[0]}
                                                                        alt={item.name || 'Unnamed'}
                                                                        className="img-fluid rounded"
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link
                                                                        to={`/product/${item.product}`}>{item.name || 'Unnamed'}</Link>
                                                                </Col>
                                                                <Col md={4}>
                                                                    {item.qty} X £{((typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price)*(item.discount || 1)).toFixed(2)} = £{(item.qty * (typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price)*(item.discount || 1)).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                            </ListGroup>
                                        ) : (
                                            <Message variant="info">Order is empty</Message>
                                        )}

                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><h2>Order Summary</h2></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Subtotal:</Col><Col>£{itemsPrice.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Shipping:</Col><Col>£{yunfei.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>VAT included:</Col><Col>£{Shui.toFixed(2)}</Col></Row></ListGroup.Item>
                                    <ListGroup.Item><Row><Col>Total:</Col><Col>£{totalPrice.toFixed(2)}</Col></Row></ListGroup.Item>
                                    {!isPay && (
                                        <ListGroup.Item>
                                            {!sdkReady ? <LoadSpinner /> : (
                                                <PayPalButton
                                                    amount={totalPrice.toFixed(2)}
                                                    currency="GBP"
                                                    onSuccess={successPaymentHandler}
                                                />
                                            )}
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default OrderTouristScreen;
