import axios from 'axios'
import {messageUpdate} from './messageActions'
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_REQUEST_DATA,
    SET_SIDEBAR_SHOW,
} from '../constants/cartConstants'

import { baseAPIUrl } from '../constants/apiConstants'

export const getCart = (id, qty) => async (dispatch, getState) => {
    // const {data} = await axios.get(baseAPIUrl+`cart`)
    // console.log('data', data);
    let data =  localStorage.getItem('cartItems')
    data = data?JSON.parse(data) : {};
    dispatch({
        type: CART_REQUEST_DATA,
        payload: data,
    })
    // localStorage.setItem('cartItems', JSON.stringify(getState().cart))
}
export const addToCart = (data, qty, color, combo, sidebarShow) => async (dispatch, getState) => {
    // 清理 image 链接
    const cleanImage = (data.image || '/default-image.jpg').split('?')[0];

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: data.id,
            name: data.name,
            image: cleanImage, // 使用清理后的 image
            price: data.price,
            countInStock: data.total,
            discount: data.discount,
            hazard: data.hazard,
            scentTotal: data.scentTotal,
            qty,
            color,
            combo,
            sidebarShow
        }
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart));
};


export const setSidebarShow = (show) => (dispatch, getState) => {
    dispatch({
        type: SET_SIDEBAR_SHOW,
        payload: show,
    })
}


export const removeFromCart = (index) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: index,
    })

    //localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const saveShippingAddress = (data, callback) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))
    if(typeof callback == 'function') {
        console.log(callback,'callback')
        callback();
    }
}



export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))
}
