import React from 'react'
import { Link } from 'react-router-dom'

function Footer(props) {
    console.log('footer', props)
    const phoneNumber = '07758943430';

    return (
        <footer>
            <div className="bottom">
                <Link to="/">
                    <img src="./images/index/logo1.png" className="botmImg" />
                </Link>
                <div className="flex bottomBox">
                    <div className="btmUl"><Link to='/about'>About</Link></div>
                    <div className="btmUl"><Link to='/contact'>Contact</Link></div>
                    <div className="btmUl"><Link to='/termsdelivery'>Delivery & Returns</Link></div>
                    <div className="btmUl"><Link to='/condition'>Terms & Conditions</Link></div>
                    <div className="btmUl"><Link to='/privatepolicy'>Privacy Policy</Link></div>
                </div>
                <a href={`tel:${phoneNumber}`}>Customer Support Team: {phoneNumber}</a>
                <br />
                <a href="mailto:info@autosqueak.com">Email: info@autosqueak.com</a>
            </div>
            <div className="foot">Copyright © 2021 AutoSqueak. All rights reserved.</div>
        </footer>
    )
}

export default Footer
