import React, { useState, useEffect, useRef } from 'react';
import '../css/DesignScreen.css';
import {
  getMyDesign,
  deleteMyDesign,
  updateProductName,
  getMyDesignDataList,
  updateOrderProductNameApi,
  deleteMyOrderApi,
} from '../actions/designActions';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import LoadSpinner from '../components/LoadSpinner';
import { useReactive } from 'ahooks';
import {
  App,
  Button,
  Carousel,
  ConfigProvider,
  Input,
  Pagination,
  Spin,
  Table,
  message,
} from 'antd';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

const carCards = [
  {
    key: 0,
    title: ['Artwork', 'Order ID'],
  },
  {
    key: 1,
    title: ['Artwork', 'Name'],
  },
  {
    key: 2,
    title: ['Artwork', 'Date Created'],
  },
  {
    key: 3,
    title: ['Artwork', 'Actions'],
  },
];

function DesignScreen({ history }) {
  const messageInfo = useSelector((state) => state.message);

  const state = useReactive({
    loading: false,
    list: [],
    pageIndex: 1,
    pageSize: 5,

    totalPageArray: [],

    total: 0,
  });

  const getList = async () => {
    state.loading = true;
    const data = await getMyDesignDataList({
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
    }).finally(() => {
      state.loading = false;
    });

    state.list = data.orders || [];
    state.total = data.total || 0;
  };

  useEffect(() => {
    getList();
  }, []);

  const reOrder = (item) => {
    localStorage.setItem('reOrder', JSON.stringify(item));
    history.push('/freshener/' + item.product.id);
  };

  const editOrderProductName = async (row) => {
    console.log(`row ->:`, row);
    const data = await updateOrderProductNameApi({
      pid: row.product.id,
      name: row.product.name,
    });
    console.log(`data ->:`, data);
    if (data?.code == 0) {
      message.success('Update Success');
      getList();
    } else {
      message.error(data.message);
    }
  };

  const deleteOrderCon = async (id) => {
    const data = await deleteMyOrderApi(id);
    if (data?.message?.includes('successfully')) {
      message.success('Delete Success');
      getList();
    } else {
      message.error(data.message);
    }
  };

  const carouselRef = useRef(null);

  if (state.loading) {
    return <Spin />;
  }

  return (
    <div className="right DesignScreen">
      {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

      <div className="topName flex-center">
        <div className="welcome">My Designs</div>
      </div>

      <div className="flex-between createUl">
        <div className="detail">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Duis nonlacinia turpis. Nunc
          pretium lacinia dolor aciaculis. Nullam ut viverra ve lit. N unc pretium laciniadolor ac
          iaculis. Nullam ut viverra velit.
        </div>
        <div className="create" onClick={() => history.push('/shapes')}>
          Create A New Design
        </div>
      </div>

      <div className="main-wrap">
        <div className="paging-content">
          <div className="basket-contents">
            <div className="pageItem paging1 active">
              <Table
                loading={state.loading}
                className="table"
                columns={[
                  {
                    title: 'Artwork',
                    render: (text, record) => (
                      <img src={record?.product?.image || ''} alt="artwork" />
                    ),
                  },
                  {
                    title: 'Order ID',
                    dataIndex: 'id',
                  },
                  {
                    title: 'Name',
                    render: (text, record) => {
                      return (
                        <>
                          <Input
                            placeholder="Design Name"
                            value={record.product.name}
                            onInput={(e) => {
                              record.product.name = e.target.value;
                            }}
                            variant="borderless"
                            className="inputName"
                          />
                          <Button className="edit-btn" onClick={() => editOrderProductName(record)}>
                            edit
                          </Button>
                        </>
                      );
                    },
                  },
                  {
                    title: 'Date Created',
                    dataIndex: 'date_created',
                  },
                  {
                    title: 'Actions',
                    render: (text, record) => (
                      <div>
                        <Button type="primary" onClick={() => reOrder(record)}>
                          Re-Order
                        </Button>
                        <Button type="danger" onClick={() => deleteOrderCon(record.id)}>
                          Remove
                        </Button>
                      </div>
                    ),
                  },
                ]}
                dataSource={state.list}
                pagination={{
                  total: state.total,
                  pageSize: state.pageSize,
                  current: state.pageIndex,
                  onChange: (page, pageSize) => {
                    state.pageIndex = page;
                    state.pageSize = pageSize;
                    getList();
                  },
                }}
                rowKey="id"
              />
            </div>
            <div className="orderDetail">
              <ConfigProvider
                theme={{
                  components: {
                    Carousel: {
                      arrowSize: 26,

                      dotOffset: -6,
                    },
                  },
                }}
              >
                <Carousel draggable ref={carouselRef} arrows infinite={false} dotPosition="top">
                  {carCards.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <div className="basket-formTwo-name">
                          <span>{item?.title[0]}</span>
                          <span>{item?.title[1]}</span>
                        </div>
                        {state.list.map((it) => {
                          return (
                            <div key={it.id}>
                              <div className="basket-formTwo-content">
                                <div className="flex">
                                  <img
                                    src={it?.product?.image_new || ''}
                                    className="basket-goods-img"
                                    alt="artwork"
                                  />
                                  <div className="flex"></div>
                                  {item.key == 0 && (
                                    <div className="basket-goods-desc">
                                      <div>{it?.id}</div>
                                    </div>
                                  )}
                                  {item.key == 1 && (
                                    <div className="basket-goods-desc">
                                      <div>{it?.product?.name}</div>
                                    </div>
                                  )}
                                  {item.key == 2 && (
                                    <div className="basket-goods-desc">
                                      <div>{it?.date_created}</div>
                                    </div>
                                  )}
                                  {item.key == 3 && (
                                    <>
                                      <div
                                        className="basket-btn PayOrder"
                                        onClick={() => reOrder(it)}
                                      >
                                        Pay-Order
                                      </div>
                                      <div
                                        className="basket-btn"
                                        onClick={() => deleteOrderCon(it.id)}
                                      >
                                        Remove
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </Carousel>
              </ConfigProvider>

              <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  total={state.total}
                  pageSize={state.pageSize}
                  current={state.pageIndex}
                  onChange={(page, pageSize) => {
                    state.pageIndex = page;
                    state.pageSize = pageSize;
                    getList();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignScreen;
